import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Router from './Router';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: `#87cefa`,
      main: `#004f9b`, // 76aa43 00bfff 004f9b
      dark: `#1e90ff`,
      contrastText: "#fff",
    },
    secondary: {
      light: `#ff5a45`,
      main: `#ff0019`,
      dark: `#c20000`,
      contrastText: "#fff",
    },
  },
  typography: {
    // Use the system font over Roboto.
    fontFamily:`-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Arial, "メイリオ", Meiryo, sans-serif`,
    useNextVariants: true,
    },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
      </MuiThemeProvider>
    );
  }
}

export default App;
